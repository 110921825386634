<template>
  <wit-block-content-container class="wit-lift-panel">
    <div class="panel-title fc-black">
      沃丁生活
    </div>
    <div class="life">
      <life-content-block v-for="(life, index) in lifeDesc" :key="index"
                          :mode="life.floatType"
                          :main-title="life.title"
                          :descriptions="life.lifeDesc"
                          @ContentUpdated="ContentUpdated"
                          :life-img-url="life.imgUrl"></life-content-block>
    </div>
  </wit-block-content-container>
</template>

<script>
  import WitBlockContentContainer
    from "@/components/common/WitBaseUI/WitBlockContentContainer/WitBlockContentContainer";
  import LifeContentBlock from "@/views/joinus/WitLifePanel/LifeContentBlock";
  import {getWitLifeData} from "@/network/life";
  export default {
    name: "WitLifePanel",
    components:{
      WitBlockContentContainer,
      LifeContentBlock
    },
    created() {
      getWitLifeData().then(response => {
        // console.log(response);
        this.lifeDesc = response
      })
    },
    data(){
      return{
        lifeDesc: []
      }
    },
    methods:{
      ContentUpdated(){
        this.$emit('ContentUpdated')
      }
    }
  }
</script>

<style scoped>
  .wit-lift-panel .panel-title{
    width: 100%;
    padding-bottom: 50px;
    text-align: center;
  }
  .life{
    /*height: 1000px;*/
    width: 100%;
  }
</style>
