<template>
  <div class="join-wit">
    <el-main class="join-wit-container">
      <img-title-panel bg-img="/images/joinus/join_bg.jpg" height="600px">
        <div class="page-title">加入沃丁</div>
        <div class="page-title-tip">开启人工智能职业之旅，创造属于你自己的价值</div>
      </img-title-panel>
      <recruitment-link-panel></recruitment-link-panel>
      <wit-life-panel @ContentUpdated="ContentUpdated"></wit-life-panel>
    </el-main>
  </div>
</template>

<script>
  import ImgTitlePanel from "@/components/content/ImgTitlePanel/ImgTitlePanel";
  import RecruitmentLinkPanel from "@/views/joinus/RecruitmentLinkPanel/RecruitmentLinkPanel";
  import WitLifePanel from "@/views/joinus/WitLifePanel/WitLifePanel";
  export default {
    name: "JoinUs",
    components:{
      ImgTitlePanel,
      RecruitmentLinkPanel,
      WitLifePanel
    },
    mounted() {
      this.$emit('ContentMounted')
    },
    activated() {
      this.$emit('ContentActivated')
    },
    methods:{
      ContentUpdated(){
        this.$emit('ContentUpdated')
      }
    }
  }
</script>

<style scoped>
  .join-wit .join-wit-container{
    padding: 0 !important;
  }
  .join-wit .join-wit-container .page-title{
    margin-bottom: 20px;
  }
</style>
