<template>
  <wit-block-content-container class="recruitment-link-panel"
                               content-class="w-l-row-center-around"
                               :content-style="{'padding':'100px 0'}">
    <recruitment-link-item icon-class="iconfont icon-shehuixinxi">热招岗位</recruitment-link-item>
    <recruitment-link-item icon-class="iconfont icon-xiaoyuan">实习生</recruitment-link-item>
  </wit-block-content-container>
</template>

<script>
  import WitBlockContentContainer
    from "@/components/common/WitBaseUI/WitBlockContentContainer/WitBlockContentContainer";
  import RecruitmentLinkItem from "@/views/joinus/RecruitmentLinkPanel/RecruitmentLinkItem";
  export default {
    name: "RecruitmentLinkPanel",
    components:{
      WitBlockContentContainer,
      RecruitmentLinkItem
    }
  }
</script>

<style scoped>

</style>
