<template>
  <div class="recruitment-link-item">
    <div class="content item-center left">
      <div class="icon">
        <i :class="iconClass"></i>
      </div>
      <div class="link-container">
        <div class="block-title">
          <slot></slot>
        </div>
        <div class="link item-center left">
          <a href="javascript:void(0)">
            查看职位
          </a>
          <i class="iconfont icon-right-arrow"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "RecruitmentLinkItem",
    props:{
      iconClass:{
        type:String,
        default:()=>''
      }
    }
  }
</script>

<style scoped>
  .recruitment-link-item{
    width: 578px;
    height: 187px;
    background: #f0f0f0;
  }
  .recruitment-link-item .content{
    width: 100%;
    height: 100%;
    align-items: center;
  }
  .recruitment-link-item .content .icon{
    margin-right: 80px;
    margin-left: 120px;
  }
  .recruitment-link-item .content .icon i{
    font-size: 100px;
    color: var(--color-tint);
  }
  .recruitment-link-item .content .link-container .block-title{
    margin: 10px 0;
  }
  .recruitment-link-item .content .link-container .link a{
    font-size: 16px;
    margin-right: 30px;
  }
  .recruitment-link-item:hover .content .link-container .link a,
  .recruitment-link-item:hover .content .link-container{
    color: var(--color-tint);
  }
  .recruitment-link-item .content .link-container .link i{
    transition: 0.5s all;
    transform-origin: left;
  }
  .recruitment-link-item:hover .content .link-container .link i{
    transform: scaleX(2);
  }
</style>
