<template>
  <div class="life-content-block w-l-row-center-compact">
    <div class="desc-container w-l-column-rs-compact w-l-jc-start" :style="descStyle">
      <div class="main-title panel-title fc-black">
        {{ mainTitle }}
      </div>
      <div class="desc-content-block" v-for="(item, index) in descriptions" :key="index">
        <div class="sub-title block-title">
          {{ item.title }}
        </div>
        <div class="description font-18">
          {{ item.content }}
        </div>
      </div>
    </div>
    <div class="img-container" :style="imgStyle" :class="{'left': isRightMode}">
      <img :src="lifeImgUrl" alt="">
    </div>
  </div>
</template>

<script>
  export default {
    name: "LifeContentBlock",
    props:{
      mode: {
        type: Number,
        default:() => 0
      },
      mainTitle: {
        type: String,
        default:() => ''
      },
      descriptions: {
        type: Array,
        default:() => []
      },
      lifeImgUrl: {
        type: String,
        default:() => ''
      },
    },
    data(){
      return{
        descStyle: {
          order: 1
        },
        imgStyle: {
          order: 2
        }
      }
    },
    mounted() {
      if(this.isRightMode){
        this.descStyle.order = 2
        this.imgStyle.order = 1
      } else {
        this.descStyle.order = 1
        this.imgStyle.order = 2
      }
    },
    computed:{
      isRightMode(){
        return this.mode === 1;
      }
    },
    updated() {
      this.$emit('ContentUpdated')
    }
  }
</script>

<style scoped>
  .life-content-block{
    position: relative;
    width: 100%;
    height: 500px;
    margin: 40px 0;
  }
  .life-content-block .desc-container{
    width: 50%;
    height: 100%;
    position: relative;
  }
  .life-content-block .img-container{
    width: 50%;
    height: 100%;
    position: relative;
  }
  .life-content-block .desc-container .main-title{
    position: relative;
    padding-left: 20px;
    margin-bottom: 50px;
  }
  .life-content-block .desc-container .main-title:before{
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 3px;
    width: 4px;
    height: 35px;
    background: var(--color-tint);
  }
  .life-content-block .desc-container .desc-content-block{
    width: 100%;
    margin-bottom: 40px;
  }
  .life-content-block .desc-container .desc-content-block .sub-title{
    margin-bottom: 20px;
  }
  .life-content-block .desc-container .desc-content-block .description{
    line-height: 30px;
    letter-spacing: 1px;
    width: 90%;
  }
  .life-content-block .img-container img{
    max-width: 49vw;
    max-height: 500px;
  }
  .life-content-block .img-container.left img{
    padding-right: 10%;
    position: absolute;
    right: 0;
    top: 0;
  }
</style>
